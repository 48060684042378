<template>
  <div id="app">
    <div class="container">
      <baidu-map
        :zoom="zoom"
        class="map"
        :center="center"
        pinch-to-zoom
        scroll-wheel-zoom
        map-type="BMAP_HYBRID_MAP"
      >
        <bm-info-window
          :position="position"
          :title="winTitle"
          :show="isShow"
          @close="isShow = false"
          @open="isShow = true"
        >
          <div class="win-box">
            <div
              v-for="(item, i) in deviceInfo.nameValues"
              :key="i"
              v-if="item.name != '设备名称' && item.name != '所属系统'"
            >
              <span>{{ item.name }}：</span><span>{{ item.value }}</span>
            </div>
          </div>
        </bm-info-window>
        <bm-map-type
          :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']"
          :offset="{ width: 10, height: 90 }"
          anchor="BMAP_ANCHOR_TOP_RIGHT"
        ></bm-map-type>
        <bml-marker-clusterer :averageCenter="true">
          <!-- @click="markerClicked(marker)" -->
          <bm-marker
            v-for="(marker, i) of markerList"
            :key="i"
            :position="{ lng: marker.lng, lat: marker.lat }"
            @click="markerClicked(marker)"
            :icon="{
              url: baseUrl + marker.iconurl,
              size: { width: 45, height: 45 },
            }"
            :id="marker.deviceId"
          >
          </bm-marker>
        </bml-marker-clusterer>
      </baidu-map>
      <div class="empt"></div>
      <div class="device-menu">
        <el-tabs v-model="activeName">
          <!-- <el-tab-pane label="设备列表" name="first"> -->
          <div class="left">
            <div class="inp">
              <el-input
                placeholder="输入关键字进行搜索..."
                clearable
                prefix-icon="el-icon-search"
                v-model="filterText"
                style="margin: 0 10px 5px 0; width: 100%"
              >
              </el-input>
            </div>
            <div class="tree">
              <el-tree
                :data="deviceInfoList"
                :props="defaultProps"
                highlight-current
                default-expand-all
                node-key="id"
                :filter-node-method="filterNode"
                :expand-on-click-node="false"
                @node-click="getPickCompant"
                ref="tree"
              >
                <span slot-scope="{ node, data }">
                  <span :title="node.label">{{
                    node.label ? node.label : data.deviceId
                  }}</span>
                </span>
              </el-tree>
            </div>
          </div>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { BmlMarkerClusterer } from "vue-baidu-map";
import DeviceHistoryValue from "@/api/manageApi/DeviceHistoryValue";
import deviceTypeAboutMap from "@/api/manageApi/DeviceTypeAboutMap.js";
import companySystem from "@/api/managementApi/CompanyInfo";
import BaseData from "@/assets/config/BaseData";
export default {
  components: {
    BmlMarkerClusterer,
  },
  props: {},
  data() {
    return {
      baseUrl: BaseData.baseUrl,
      zoom: 16,
      center: { lng: 119.696038, lat: 39.9425 },
      activeName: "first",
      markerList: [],
      // 分级设备集合
      deviceInfoList: [],
      defaultProps: {
        children: "deviceList", // 子公司节点
        label: "deviceName", // 公司名
      },
      // 查找设备过滤
      filterText: "",
      isShow: false,
      allCompanyList: [],
      deviceInfo: {},
      winTitle: "", // 窗体名称
      position: {},

      deviceMap: new Map(), //用来存储每个设备对应的索引
      staticValueList: [], //用来存储每个设备对应的数据

      deviceObj: {},

      selectEquipmentId: "",
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },

  created() {
    console.log("map");
    this.getDeviceInfo();
  },
  mounted() {},

  methods: {
    // 选择设备过滤器
    filterNode(value, data) {
      if (
        data.deviceId.indexOf(value) != -1 ||
        (data.deviceName != undefined && data.deviceName.indexOf(value) != -1)
      ) {
        return true;
      } else {
        return false;
      }
    },

    async getDeviceInfo() {
      let obj = {};
      let dayTtype = "Daily"; // 时间类型
      let deviceType = "DXwaterMeter"; // 设备类型
      let current = 1; // 当前页
      let limit = 999; // 条数
      obj.type = dayTtype;
      obj.deviceTypeId = deviceType;
      // obj.startTime = this.dataTime[0]
      // obj.endTime = this.dataTime[1]
      obj.current = current;
      obj.limit = limit;
      const value = await DeviceHistoryValue.getWaterMeterData(obj);
      //此处将设备对应的索引存入map
      for (let i = 0; i < value.data.data.list.length; i++) {
        const element = value.data.data.list[i];
        this.deviceMap.set(element.deviceId, i);
      }
      this.staticValueList = JSON.parse(JSON.stringify(value.data.data.list));

      // 根据设备类型id查所有设备结构
      const res = await DeviceHistoryValue.getHierarchicalList(deviceType, "");
      this.deviceInfoList = res.data.data.list;
      this.soloveObj(this.deviceInfoList);
      this.recursionSort(this.deviceInfoList);
      // 根据设备类型id查所有设备经纬度
      const { data: decvice } = await deviceTypeAboutMap.getDeviceTypeAboutMap(
        deviceType
      );
      this.markerList = decvice.data.list;
      // 查所有公司
      const { data: company } = await companySystem.getCompanyInfo();
      this.allCompanyList = company.data.list;
    },

    // 递归排序
    recursionSort(val) {
      val.sort((a, b) => a.number - b.number);
      let data = val;
      for (let i in data) {
        if (data[i].deviceList) {
          this.recursionSort(data[i].deviceList);
        }
      }
    },

    //处理数据
    soloveObj(list) {
      for (let i = 0; i < list.length; i++) {
        let element = list[i];
        let deviceId = element.deviceId;
        if (this.deviceMap.has(deviceId)) {
          let obj = this.staticValueList[this.deviceMap.get(deviceId)];
          let valueList = obj.valueList; //用水量集合数据
          let sumTotal = Number(0); //设置总用水量
          let coding = obj.coding;
          let number = obj.number;
          for (let j = 0; j < valueList.length; j++) {
            sumTotal += Number(valueList[j]);
          }
          element.sumTotal = Number(sumTotal).toFixed(2);
          element.leakage = "无";
          element.coding = coding;
          element.number = number;
          let sonSumTotal = Number(0); //子设备总用水
          if (obj.leakage != undefined) {
            for (const key in obj.leakage) {
              const element1 = obj.leakage[key];
              for (let j = 0; j < element1[0]["valueList"].length; j++) {
                sonSumTotal += Number(element1[0]["valueList"][j]);
              }
            }
            if (Number(sumTotal.toFixed(2)) > Number(sonSumTotal.toFixed(2))) {
              element.leakage =
                (
                  ((sumTotal.toFixed(2) - sonSumTotal.toFixed(2)) /
                    sumTotal.toFixed(2)) *
                  100
                ).toFixed(2) + "%";
            }
          }
          if (element.deviceList != undefined) {
            this.soloveObj(element.deviceList);
          }
        }
      }
    },

    // 通过设备id查设备坐标
    async getPickCompant(value) {
      this.selectEquipmentId = value.deviceId;

      // document.getElementById()

      this.zoom = 30;
      this.center = {};
      // 设置点的动画
      for (let i = 0; i < this.markerList.length; i++) {
        if (value.deviceId == this.markerList[i].deviceId) {
          this.center.lng = this.markerList[i].lng;
          this.center.lat = this.markerList[i].lat;
        }
      }
      if (!this.center.lng) {
        this.center = { lng: 119.696038, lat: 39.9425 };
        this.$message.warning("该设备未添加位置信息！");
      }
    },

    // 点击mark弹窗事件
    markerClicked(value) {
      this.deviceInfo = [];
      this.deviceObj = {};

      this.isShow = true;
      this.position.lng = value.lng;
      this.position.lat = value.lat;
      this.deviceInfo = value;
      // this.deviceObj.name = "当前设备编号";
      // this.deviceObj.value = value.deviceId;

      // this.deviceInfo.nameValues.unshift(this.deviceObj);

      for (let i = 0; i < this.deviceInfo.nameValues.length; i++) {
        if (this.deviceInfo.nameValues[i].name == "设备名称") {
          this.winTitle = this.deviceInfo.nameValues[i].value;
        } else if (this.deviceInfo.nameValues[i].name == "布防状态") {
          this.deviceInfo.nameValues[i].value == 0
            ? (this.deviceInfo.nameValues[i].value = "撤防")
            : (this.deviceInfo.nameValues[i].value = "布防");
        } else if (this.deviceInfo.nameValues[i].name == "绑定公司") {
          this.deviceInfo.nameValues[i].name = "单位名称";
          for (let j = 0; j < this.allCompanyList.length; j++) {
            if (
              this.deviceInfo.nameValues[i].value == this.allCompanyList[j].id
            ) {
              this.deviceInfo.nameValues[i].value = this.allCompanyList[j].name;
            }
          }
        } else if (this.deviceInfo.nameValues[i].name == "上级设备") {
          this.deviceInfo.nameValues[i].name = "上级设备编号";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 去除滚动条
::-webkit-scrollbar {
    display: none;
    height: 94%;
  }
  // 树形图的样式
/deep/.el-tree-node__content {
    height: 30px;
    font-size: 15px;
    line-height: 30px;
  }
  .el-tree {
    color: #80ecf0;
    background: none;
  }
  /deep/.el-tree-node__content:hover {
    background: #fff;
    font-weight: 600;
    border-radius: 3px;
  }
.container {
  height: 100%;
  color: aliceblue;
  position: relative;

  .map {
    height: 100%;

    .win-box {
      padding: 15px 0;
      width: 300px;
      height: 100px;
      line-height: 25px;

      div {
        width: 100%;
        box-sizing: border-box;

        span:nth-child(1) {
          display: inline-block;
          width: 35%;
        }

        span:nth-child(2) {
          width: 50%;
          display: inline-block;
        }
      }
    }
  }

  .empt {
    position: absolute;
    top: 0;
    height: 80px;
    background: linear-gradient(
      to right,
      rgba(4, 78, 163, 0.4),
      rgba(157, 180, 196, 0.1)
    );
    width: 320px;
  }

  .device-menu {
    backdrop-filter: blur(5px);
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0px;
    width: 320px;
    background: linear-gradient(
      to right,
      rgba(4, 78, 163, 0.4),
      rgba(103, 175, 223, 0.2)
    );
    z-index: 9;
    padding: 10px;
    // height: 100%;

    .left {
      .inp {
      }

      .tree {
        height: 700px;
        overflow: scroll;
      }
    }
  }
}

/deep/.el-tabs__item {
  color: #fcfcfc;
  font-size: 16px;
}

/deep/.el-tabs__item:hover {
  color: #83adec;
  cursor: pointer;
}

/deep/.el-tabs__item.is-active {
  // color: aqua;
}
/deep/.el-input__inner {
  background: rgba(4, 78, 163, 0.4);
  color: #fcfcfc;
}
/deep/.el-tabs__nav-wrap::after {
  height: 1px;
}

/deep/.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: rgba(4, 78, 163, 0.4);
}
/deep/.BMap_bubble_title {
  font-weight: 600;
  font-size: 16px;
}

/deep/.el-tree-node__content:hover {
  background: rgba(4, 78, 163, 0.4);
}

/deep/.el-upload-list__item:hover {
  background: rgba(4, 78, 163, 0.4);
}

/deep/ img {
  width: 100%;
  height: 100%;
}
</style>